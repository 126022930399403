import React, { useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';
import TablePagination from '@mui/material/TablePagination';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormControl, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { getBLockNumber, transValue } from "../../utils";

const columns = [
  { id: "TxnHash", label: "Trans Hash"},
  { id: "TxnHash", label: "Block Height", minWidth: 50},
  // { id: "Method ", label: "Block ID "},
  { id: "Age", label: "Timestamp"},
  { id: "Block", label: "From" },
  { id: "From", label: "To" },
  { id: "Age", label: "Value" },
  { id: "To", label: "NODE"},
  { id: "Value", label: "Origin"},
  { id: "TxnFee", label: "Status", minWidth: 120},
];

function Newtransaction() {
  const [transaction, setTransaction] = useState([]);
  const [limit, setLimit] = useState(0);
  const url = 'https://mhiservers3.com'

  const [transactionPage, setTransactionPage] = React.useState(10);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [PreviousPage, setPreviousPage] = React.useState(true);
  const [nextPage, setNextPage] = React.useState(false);
  const handleChange = (event) => {
    setTransactionPage(event.target.value);
  };
  const handleNext = (event) => {
    setPageIndex(pageIndex + 1);
  };
  const handleLastPage = (event) => {
    setPageIndex(Math?.ceil(transaction.totalPages  - 1));
  };
  const handlePrevious = (event) => {
    setPageIndex(pageIndex - 1);
  };
  const handleFirstPage = (event) => {
    setPageIndex(Math?.ceil(transaction.totalPages  - 2));
  };

  // const viewBlock = () => {
  //   const body = {
  //       minLimit: 10,
  //   };
  //   axios
  //       .get("https://mhiservers3.com/wallet/get_current_index_coin", body)
  //       .then((res) => {
  //           console.log(res.data,"newblock")
  //           // setpecuscanData(res.data);

  //       })
  //       .catch((err) => console.log(err));
  // };
  // useEffect(() => {
  //   viewBlock();
  // }, []);


  const transactionData = () => {
    axios
      .get(`${url}/blockchain/transactions?page=${pageIndex}&pageSize=${transactionPage}`)
      .then((res) => {
      
 
        setTransaction(res.data);
      });
  };
  // const transactionData = () => {
  //   axios
  //     .post(`${url}/wallet/public_ledger_all_data`, {
  //       minLimit: limit,
  //       maxLimit: limit + 5
  //     })
  //     .then((res) => {
  //       setLimit(limit + 5);
  //       let mydataf = [];
  //       res?.data?.forEach((e) => {
  //         if (e?.key && e?.key.length > 0) {
  //           mydataf?.push(e);
  //         } else {
  //           e.key = makeid(128);
  //           mydataf?.push(e);
  //         }

  //         if (res?.data?.length == mydataf?.length) {
  //           setTransaction(mydataf);
  //         }
  //       });
  //     });
  // };
  useEffect(() => {
    transactionData();
  }, [])
  useEffect(() => {
    transactionData();
  }, [pageIndex,transactionPage])

  return (
    <>
      <section className="container" style={{ padding: "0" }}>
        <div style={{ paddingBottom: "48px" }}>
          <div className="blockCard">
            {/* <div className="cardHeader">
              <div style={{ padding: "16px" }}>
                <div>
                  <span>More than 1,903,656,162 transactions found</span>
                  <p>(Showing the last 500k records)</p>
                </div>
              </div>
            </div> */}
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          className="tableHead"
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transaction?.transactions
                    ?.slice(0, transactionPage )
                      .map((index) => {
                        return (
                          <>
                            <TableRow hover>
                              {/* <TableCell><a style={{ color: "rgb(7, 132, 195)" }}>{index.id}</a></TableCell> */}
                              <TableCell><Link to={`/transaction/${index.hash}`} state={index}><a className="text-truncate" style={{ width: "128px", display: 'block',cursor:'pointer', color: "rgb(7, 132, 195)" }}>{(index.hash)}</a></Link></TableCell>
                             
                              <TableCell><Link to={`/transaction/${index.hash}`} state={index}><a className="text-truncate" style={{ width: "128px", display: 'block',cursor:'pointer', color: "rgb(7, 132, 195)" }}>{getBLockNumber(index.block)}</a></Link></TableCell>
                              {/* <TableCell><a style={{ color: "rgb(7, 132, 195)" }}>{index.block_no }</a></TableCell> */}
                              <TableCell>{new Date(index.timestamp).toUTCString()}</TableCell>
                              <TableCell><a className='truncateTransaction'>{index.sender}</a></TableCell>
                              <TableCell><a className='truncateTransaction'>{index.recipient}</a></TableCell>
                               <TableCell>{transValue(index)} USD</TableCell>
                              <TableCell>{index.node}</TableCell>
                              <TableCell>{index.origin}</TableCell>
                              <TableCell>
                              <span className="successButton">
                                        <i>
                                            <CheckCircleIcon />
                                        </i>
                                        Success
                                    </span>
                                </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ padding: '16px', display:'flex', alignItems: 'center', justifyContent:'space-between' }}>
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                <div>Show:</div>
                <FormControl >
                    <select
                      value={transactionPage}
                      onChange={handleChange}
                      className="paginations"
                    >
                      {[10, 25, 50, 100].map((transactionPage) => (
                        <option key={transactionPage} value={transactionPage}>
                          {transactionPage}
                        </option>
                      ))}
                    </select>
                  </FormControl>
                </div>
                <div>
                    <div style={{display:'flex', gap:'4px'}}>
                      <button  className="firstLast" onClick={handleFirstPage} disabled={pageIndex === 0  }><a>First</a></button>
                      <button className="firstLast" onClick={handlePrevious} disabled={pageIndex === 0 }><i ><ArrowBackIosNewIcon/></i></button>
                      <button className="firstLast"><span>Page {pageIndex + 1} </span></button>
                      <button className="firstLast" onClick={handleNext} ><i ><ArrowForwardIosIcon/></i></button>
                     
                    </div>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </section>
    </>
  );
}
export default Newtransaction;
